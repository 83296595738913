import React, { useState, useNavigate } from "react";
import { NavLink } from 'react-router-dom';
import { PHONE, PHONE_HREF,ADDRESS } from '../../config';
import {
    logo,
    bannerMain,
    bannerMain_02,
    bannerMain_03,
    bnnrMobImg,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    about1,
    about2,
    textcircle_01,
    iconic_logo,
    tabsbg,
    portfolioImg,
    portfolioImg2,
    portfolioImg3,
    portfolioImg4,
    portfolioImg5,
    portfolioImg6,
    portfolioImg7,
    pec1,
    pecBor,
    pec2,
    pec3,
    pec4,
    packagecombo1,
    testimonalsText,
    qoitebg,
    test1,
    test2,
    test3,
    remedybrand,
    ipad,
    cards,
    threeDLogo,
    animtedLogo,
    logoGif,
    packageImg
} from '../../assest/images'
import { WEBSITE_NAME } from "../../config";
import { OurServices } from "../../Component/ourServices"
import { useModal } from "../../ModalContext";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { OurPricing } from "../../Component/ourPricing";
import { LogoInfinite } from "../../Component/logoinfinite";
import { HappyClient } from "../../Component/happyClients";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';


function LP() {
    const { openModal } = useModal();
    // const naviagte = useNavigate();
    const [loading, setLoading] = useState(false);

    const [countstep, setCountStep] = useState(0);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const handleNextClick = () => {
        // setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
        let canProceed = false;

        switch (countstep) {
            case 0:
                canProceed = formData.name.trim() !== "";
                break;
            case 1:
                canProceed = formData.email.trim() !== "";
                break;
            case 2:
                canProceed = formData.phone.trim() !== "";
                break;
            default:
                canProceed = true;
        }

        if (canProceed) {
            setCountStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
        } else {
            if (countstep == 0) {
                alert("Please fill this name required field.");
            } else if (countstep == 1) {
                alert("Please fill this email required field.");
            } else {
                alert("Please fill this phone required field.");
            }
        }
    };

    const handlePrevClick = () => {
        setCountStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log("formData", formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;

            const protocol = window.location.protocol;  // "https:"
            const hostname = window.location.hostname;  // "logoaura.com"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`
            // console.log("baseUrl",baseUrl);

            // Log the URL to the console (optional)
            console.log(currentUrl);

            const queryStringFormData = new URLSearchParams(formData).toString()



            let finalReq = {
                ...formData,
                source: `https://www.ozlogodesigns.com/${queryStringFormData}`,
                domain: baseUrl,
                lead_url: baseUrl,
                url: `https://www.ozlogodesigns.com/${queryStringFormData}`,
                // lead_url: baseUrl,
            };
            try {
                setLoading(true);
                const apiUrl = "https://tgcrm.net/api/form_submission?brand_key=338176";
                const config = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status == 200) {
                    // toast.success("Thank you for filling out the information");
                    console.log("response", response.data.data);
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log("queryString", queryString);
                    //  console.log(`/thank-you?${queryString}`);
                    // console.log(response.data.data.id);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                    });
                    setCountStep(0);
                    // window.location.href=`http://localhost:3000/thank-you?${queryString}`
                    window.location.href = `https://www.ozlogodesigns.com/thank-you?${queryString}`;
                }
                console.log("responseresponseresponse", response);
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };
    return (
        <>
        <header>
        <nav>
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-sm-4 col-12 responsive-logo">
                <NavLink to="/" class="navbar-brand">
                  <img class="lazyload logo_img" src={logo} alt="logo" />
                </NavLink>
              </div>
              <div class="col-sm-8 col-12">
                <ul>
                  <li class="responsive call-btn">
                    <a href={PHONE_HREF}>
                      <i class="fas fa-phone-alt"></i>
                      <span>Call Us:</span>{PHONE}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="themeBtn chat zendeskchat">Talk To Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
            <main className="content">
                <div className="heroSec lazy-background">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-7 col-md-6">
                                    <div className="content">
                                        <h4>Premiumly Crafted</h4>
                                        <h2>
                                            Custom Logo <span>Design Services</span>
                                        </h2>
                                        <p>
                                            We shape your brand with innovative Logo Designs to give
                                            your Brand a truly remarkable &amp; professional look.
                                        </p>
                                        <h4 className="bnnr-discount bnnr-discount">
                                            SIGN UP NOW AND GET UP TO <span>70% OFF</span>
                                        </h4>
                                        <div className="main-form">

                                            <form className="form_submission" onSubmit={onSubmitFunc}>
                                                <div className="frm-grup-cta">
                                                    {countstep === 0 ? (
                                                        <div
                                                            className="form-group side-form-group tab tab-1"
                                                            style={{ display: "block" }}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Enter Your Business Name"
                                                                required
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    ) : countstep === 1 ? (
                                                        <div
                                                            className="form-group side-form-group tab tab-1"
                                                            style={{ display: "block" }}
                                                        >
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                placeholder="Enter Your Business Email"
                                                                required
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="form-group side-form-group tab tab-1"
                                                            style={{ display: "block" }}
                                                        >
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="phone"
                                                                placeholder="Enter Your Phone Number"
                                                                required
                                                                min={9}
                                                                minLength={9}
                                                                value={formData.phone}
                                                                onChange={handleChange}
                                                            />
                                                            {errors && (
                                                                <div className="error">
                                                                    Phone number must be at least 9 digits long
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className="col-sm-12">
                                                        <p style={{ marginTop: 20, fontSize: 12 }}>
                                                            {" "}
                                                            By clicking "Submit," you confirm that you agree
                                                            to
                                                            {/*?php echo WEBSITE_NAME ?*/}{" "}
                                                            <a href="/privacy-policy"> Privacy Policy. </a>
                                                        </p>
                                                    </div>
                                                    <div className="frm-grup-cta-btn">
                                                        {countstep > 0 && (
                                                            <button
                                                                className="themeBtn tab-1-btn prev"
                                                                type="button"
                                                                style={{ display: "inline" }}
                                                                onClick={handlePrevClick}
                                                            >
                                                                Prev
                                                            </button>
                                                        )}
                                                        {countstep < 2 ? (
                                                            <button
                                                                className="themeBtn tab-1-btn next"
                                                                type="button"
                                                                style={{ display: "inline" }}
                                                                onClick={handleNextClick}
                                                            >
                                                                Next
                                                            </button>
                                                        ) : loading === true ? (
                                                            <div
                                                                className="learn-more-tab themeBtn"
                                                                style={{
                                                                    display: "inline-block",
                                                                    padding: "01.rem 0.5rem",
                                                                    margin: "0px 2px",
                                                                }}
                                                            >
                                                                <img src={logoGif} alt="gif" width={"35px"} />
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="learn-more-tab themeBtn"
                                                                id="signupBtn2"
                                                                name="signupForm"
                                                                type="submit"
                                                                style={{ display: "inline" }}
                                                                disabled={loading}
                                                            >
                                                                Submit
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-md-6 bnnr-logos-col">
                                    <div className="bannerslider banner_slider_img">

                                        <figure className="logo-wrap-disable">
                                            <img
                                                src={bannerMain_02}
                                                className="w-100 lazyload "
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <section className="logosSec">
                <div className="marquee-wrap">
                    <div className="marquee-grad" />
                    <div className="marquee-horizontal-alt-css w-embed"></div>
                    <div className="track-horizontal">
                        <div className="logos">
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo1}
                                    loading="eager"
                                    alt="Client logo: NHS"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo2}
                                    loading="eager"
                                    alt="Client logo: GfK"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo3}
                                    loading="eager"
                                    alt="Client logo: HM Gov"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo4}
                                    loading="eager"
                                    alt="Client logo: AllianceBernstein"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo5}
                                    loading="eager"
                                    alt="Client logo: Visa"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo6}
                                    loading="eager"
                                    alt="JLL logo"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo7}
                                    loading="eager"
                                    alt="Client logo: GSK"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo8}
                                    loading="eager"
                                    alt="Logo for finance client, EMVCo"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                        </div>
                        <div className="logos">
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo1}
                                    loading="eager"
                                    alt="Client logo: NHS"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo2}
                                    loading="eager"
                                    alt="Client logo: GfK"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo3}
                                    loading="eager"
                                    alt="Client logo: HM Gov"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo4}
                                    loading="eager"
                                    alt="Client logo: AllianceBernstein"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo5}
                                    loading="eager"
                                    alt="Client logo: Visa"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo6}
                                    loading="eager"
                                    alt="JLL logo"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo7}
                                    loading="eager"
                                    alt="Client logo: GSK"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                            <figure className="marquee-logo-wrap">
                                <img
                                    src={logo8}
                                    loading="eager"
                                    alt="Logo for finance client, EMVCo"
                                    className="marquee-logo lazyload"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="aboutSec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                            <figure className="aboutImgCont">
                                <img
                                    className="lazyload"
                                    src={about1}
                                    alt=""
                                />
                                <img
                                    className="lazyload"
                                    src={about2}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                            <div className="content">
                                <div className="secHeading">
                                    <h2>
                                        <span>
                                            Impressive
                                            <img
                                                className="lazyload"
                                                src={textcircle_01}
                                                alt=""
                                            />
                                        </span>
                                        {" "} Logos, Creating Everlasting Impression On Your Target Audience.
                                    </h2>
                                </div>
                                <p>
                                    {WEBSITE_NAME} is a digital agency that not only
                                    strives to provide its customers with top-notch logo design
                                    services, but also the best customer service that they have
                                    experienced. Comprising of a team of dedicated professionals, the
                                    team has to its credit thousands of completed project deliveries and
                                    more.
                                </p>
                                <div className="about-sec-buttons">
                                    <a href="javascript:;" className="themeBtn chat zendeskchat2">
                                        Live Chat
                                    </a>
                                    <a href="javascript:;" className="themeBtn ml-4 openpopup" onClick={openModal}>
                                        Get Quote
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OurServices />
            <section className="cta-sec lazy-background section-padding">
                <div className="container">
                    <div className="cta-contains lazy-background">
                        <h1>
                            Designing a great logo is hard. <span> We make it easy. </span>
                        </h1>
                        <h2>
                            Call our assistant at{" "}
                            <a href="<?php echo PHONE_HREF ?>">{/*?php echo PHONE ?*/}</a>
                        </h2>
                        <div className="cta-buttons">
                            <a href="javascript:;" className="themeBtn chat zendeskchat3">
                                Live Chat
                            </a>
                            <a href="javascript:;" className="themeBtn ml-4 openpopup" onClick={openModal}>
                                Get Quote
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-portfolio lazy-background">
                <div className="container">
                    <div className="secHeading main-hdg">
                        <h2>
                            Our
                            <span>  {" "}
                                Portfolio
                                <img
                                    src={textcircle_01}
                                    alt="textcircle_01"
                                    className="lazyload"
                                />
                            </span>
                        </h2>
                    </div>
                    <div className="row portfolio-images1 no-gutters">
                        <div className="col-md-4">
                            <img
                                src={portfolioImg}
                                className="img-fluid  lazyload"
                                alt="portfolioImg"
                            />
                        </div>
                        <div className="col-md-8 pr-0">
                            <img
                                src={portfolioImg2}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                        <div className="col-md-4">
                            <img
                                src={portfolioImg3}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                        <div className="col-md-4">
                            <img
                                src={portfolioImg4}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                        <div className="col-md-4 pr-0">
                            <img
                                src={portfolioImg5}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                        <div className="col-md-8 ">
                            <img
                                src={portfolioImg6}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                        <div className="col-md-4 pr-0">
                            <img
                                src={portfolioImg7}
                                className="img-fluid  lazyload"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            <OurPricing />
            <LogoInfinite />
            <HappyClient />
            <section className="how_works">
                <div className="container">
                    <div className="secHeading main-hdg">
                        <h2>
                            How It
                            <span> {" "}
                                Works
                                <img
                                    clas="lazyload"
                                    src={textcircle_01}
                                    alt="textcircle_01"
                                />
                            </span>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="boxes">
                                <div className="icons">
                                    <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        <defs />
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <path
                                                d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z M17,16 L17,10 C17,8.34314575 15.6568542,7 14,7 L8,7 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L17,16 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                            />
                                            <path
                                                d="M9.27272727,9 L13.7272727,9 C14.5522847,9 15,9.44771525 15,10.2727273 L15,14.7272727 C15,15.5522847 14.5522847,16 13.7272727,16 L9.27272727,16 C8.44771525,16 8,15.5522847 8,14.7272727 L8,10.2727273 C8,9.44771525 8.44771525,9 9.27272727,9 Z"
                                                id="Rectangle-19-Copy-3"
                                                fill="#000000"
                                                opacity="0.3"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <h3>Details</h3>
                                <p>
                                    Answer a few questions about your business, your industry and your
                                    audience and we’ll take it from there.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="boxes">
                                <div className="icons">
                                    <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        <defs />
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <path
                                                d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                                opacity="0.3"
                                            />
                                            <circle fill="#000000" cx={12} cy={9} r={5} />
                                        </g>
                                    </svg>
                                </div>
                                <h3>Design</h3>
                                <p>
                                    Our design team gets involved and we start crafting unique logo
                                    options to reflect your business.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="boxes">
                                <div className="icons">
                                    <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        <defs />
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <path
                                                d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z"
                                                fill="#000000"
                                                opacity="0.3"
                                            />
                                            <path
                                                d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                                                fill="#000000"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <h3>Delivery</h3>
                                <p>
                                    As soon as your new logo designs are completed, we'll send them
                                    straight to your inbox.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contactsec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="secHeading main-hdg">
                                <h2>
                                    <span>
                                        Contact  {" "}
                                        <img
                                            className="lazyload"
                                            src={textcircle_01}
                                            alt="textcircle_01"
                                        />
                                    </span>
                                    Us
                                </h2>
                            </div>
                            <div className="footerform">
                                <form className="form_submission" onSubmit={onSubmitFunc}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="Enter Your Business Name"
                                                    required
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, name: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter Your Business Email"
                                                    name="email"
                                                    required
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, email: e.target.value })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    name="phone"
                                                    minLength={9}
                                                    min={9}
                                                    required
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, phone: e.target.value })
                                                    }
                                                />
                                                {errors && (
                                                    <div className="error">
                                                        Phone number must be at least 9 digits long
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <textarea
                                                    placeholder="Enter Message Here"
                                                    name="message"
                                                    className="form-control"
                                                    // defaultValue={""}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            message: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <p
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {" "}
                                                By clicking "Submit," you confirm that you agree to
                                                {/*?php echo WEBSITE_NAME ?*/}{" "}
                                                <a href="../privacy.php"> Privacy Policy. </a>
                                            </p>
                                        </div>
                                        <div className="col-sm-12">
                                            {loading == true ? (
                                                <div
                                                    className="learn-more-tab themeBtn"
                                                    style={{
                                                        display: "inline-block",
                                                        padding: "01.rem 0.5rem",
                                                        margin: "0px 2px",
                                                    }}
                                                >
                                                    <img src={logoGif} alt="gif" width={"35px"} />
                                                </div>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="themeBtn"
                                                    id="signupBtn"
                                                >
                                                    SUBMIT NOW
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pix-fancy-mockup">
                                <div className="pix-fancy-content">
                                    <img src={remedybrand} className="lazyload" alt="" />
                                </div>
                                <img
                                    className="pix-fancy-device-img lazyload"
                                    src={ipad}
                                    alt="ipad"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
        <div className="container">
          <div className="row align-items-center justify-content-between top-row">
            <div className="col-lg-5 col-md-5 col-12">
              <span>
                <a href="/terms-and-condition">Terms &amp; Conditions</a> |
                <a href="/privacy-policy">Privacy Policy </a> |
                <a href="/cookie-policy">Cookies Policy </a>
              </span>
            </div>
            <div className="col-lg-6 col-md-5 col-12 ">
              <span>
                <a href="">{ADDRESS}</a> |
                <a href={PHONE_HREF}>{PHONE}</a> 
              </span>
            </div>
          </div>
          {/* <div className="disclemar">
            <p>
              <strong>Disclaimer:</strong> The logo, name and graphics of{" "}
              logo Aura and its products &amp; services are the
              trademarks of logo Aura. All other company names,
              brand names, trademarks and logos mentioned on this website are the
              property of their respective owners and do not constitute or imply
              endorsement, sponsorship or recommendation thereof by{" "}
              logo Aura and do not constitute or imply
              endorsement, sponsorship or recommendation of{" "}
              logo Aura by the respective trademark owner.
            </p>
          </div> */}
          <div className="row align-items-center justify-content-between pt-3 ">
            <div className="col-lg-4 col-md-4 col-12 text-left">
              <p>
                Copyright © {/*?php echo date('Y'); ?*/}{" "}
                Logo Aura | All rights reserved.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-12 text-right">
              <img
                className="img-responsive img-fluid lazyload"
                src={cards}
                alt="Brand Logo"
              />
            </div>
          </div>
        </div>
      </footer>
        </>
    );
}

export default LP;
