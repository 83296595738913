import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { PHONE, PHONE_HREF, WEBSITE_LOGO } from '../../config';
import { logo } from '../../assest/images';

export const Header = () => {

  useEffect(() => {
    // Function to open chat window
    const openChatWindow = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Function to toggle chat window
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Initialize chat and open by default
    const initializeChat = () => {
      const intervalId = setInterval(() => {
        if (window.$zopim && window.$zopim.livechat) {
          clearInterval(intervalId);
          openChatWindow();
          listenForNewMessages();
        }
      }, 100); // Check every 100ms if chat widget is available
    };

    // Listen for new messages
    const listenForNewMessages = () => {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
          if (numberOfUnreadMessages > 0) {
            openChatWindow();
          }
        });
      }
    };

    initializeChat();

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.zendeskchat'); // Replace with actual button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);


  return (
    <div>

      <header>
        <nav>
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-sm-4 col-12 responsive-logo">
                <NavLink to="/" class="navbar-brand">
                  <img class="lazyload logo_img" src={logo} alt="logo" />
                </NavLink>
              </div>
              <div class="col-sm-8 col-12">
                <ul>
                  <li class="responsive call-btn">
                    <a href={PHONE_HREF}>
                      <i class="fas fa-phone-alt"></i>
                      <span>Call Us:</span>{PHONE}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="themeBtn chat zendeskchat">Talk To Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

