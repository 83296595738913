import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { Cookie } from './pages/Cookie';
import { ThankYou } from './pages/ThankYou';
import CusyomLogoDesign from './pages/LP';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/privacy-policy" element={<Privacy />} />
    <Route path="/terms-and-condition" element={<Terms />} />
    <Route path="/cookie-policy" element={<Cookie />} />
    <Route path="/thank-you" element={<ThankYou />} />
    <Route path="/lp" element={<CusyomLogoDesign />} />
  </Routes>
);

export default AppRoutes;
