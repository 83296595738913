// Default CSS for the main brand
import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Header } from './Component/header';
import {Footer} from './Component/footer';
import AppRoutes from './routes'
import { ChatProvider } from "./ChatContext";
import { ModalProvider } from "./ModalContext";
import { HelmetProvider } from 'react-helmet-async';
import usePageMeta from './usePageMeta';

function App() {
  return (
    <HelmetProvider>
      <ChatProvider>
        <ModalProvider>
          <Router>
            <MainContent />
          </Router>
        </ModalProvider>
      </ChatProvider>
    </HelmetProvider>
  );
}

function MainContent() {
  usePageMeta();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/lp') {
      import('./pages/LP/index.css');  // Dynamically import LP CSS
    } else {
      import('./App.css');  // Default CSS for the brand pages
    }
  }, [location.pathname]);


  const noHeaderFooterRoutes = ["/lp"];
  const shouldHideHeaderFooter = noHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideHeaderFooter && <Header />}
      <AppRoutes />
      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
}

export default App;

